<template>
  <input
    type="text"
    name="search"
    class="form-control form-control-lg w-full mb-4"
    :placeholder="placeholder"
    autocomplete="off"
    @input="onSearch"
    v-model="search"
  >
</template>

<script>
  let searchTimer = null;

  export default {
    name: 'ContentSearch',
    props: {
      placeholder: {
        type: String,
        default: 'Поиск'
      }
    },
    data() {
      return {
        search: null,
      };
    },
    methods: {
      onSearch() {
        clearTimeout(searchTimer);
        const timer = setTimeout(() => {
          this.$router.push({
            query: { 'query': this.search },
          });
        }, 800);

        searchTimer = timer;
      },
      onEnter(e) {
        if (e.keyCode === 13) {
          this.$router.push({
            query: { 'query': this.search },
          });
        }
      },
      fillSearchOnInit() {
        const query = this.$route.query;
        if (query && query.query) {
          this.search = this.$route.query.query;
        }
      },
    },
    mounted() {
      this.fillSearchOnInit();
    },
    beforeDestroy() {
      searchTimer = null;
    },
  };
</script>

<style></style>
