import {serialize} from '@/utils/object-to-form-data';

export const errorResponse = {
  methods: {
    errorResponse(ex) {
      this.loading = false;
      console.log('cant send request: ' + ex);
      let errorText = '';

      if (ex && ex.response) {
        if (ex.response.status === 400) {
          for (const key in ex.response.data.message) {
            this.form[key] = null;
          }
          errorText = ex.response.data.message ? ex.response.data.message : 'Введенные данные неверные';
        } else if (ex.response.data && ex.response.data.errors) {
          errorText = JSON.stringify(ex.response.data.errors);
        } else {
          errorText = ex.response.data && !!ex.response.data.message ? JSON.stringify(ex.response.data.message) : '';
        }
      }

      this.$notify({
        title: 'Ошибка',
        text: errorText,
        type: 'error',
      });
    },
  },
};

export const formPreparation = {
  methods: {
    formPreparation(allowEmptyArrays = false) {
      const form = new FormData;
      const options = {
        /**
         * добавляет индексы массивам
         * defaults to false
         */
        indices: true,

        /**
         * treat null values like undefined values and ignore them
         * defaults to false
         */
        nullsAsUndefineds: true,

        /**
         * convert true or false to 1 or 0 respectively
         * defaults to false
         */
        booleansAsIntegers: true,

        /**
         * store arrays even if they're empty
         * defaults to false
         */
        allowEmptyArrays: allowEmptyArrays,
      };

      const formDataFormatted = serialize(
        this.form,
        options,
        form,
      );

      return formDataFormatted;
    },
  },
};
