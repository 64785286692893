<template>
    <div class="simple-table">
        <div class="table-wrapper" :class="{ 'disabled-form': loading }">
            <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                <Preloader />
            </div>

            <table class="table" :class="{ 'disabled-form': loading }">
                <thead>
                    <tr class="border-b-2">
                        <th
                            v-for="header in headers"
                            class="whitespace-nowrap header-action"
                            :key="header.field"
                            :class="{ active: header.field === sortField }"
                            @click.prevent="sort(header.field)"
                        >
                            <span
                                >{{ header.label }}
                                <ChevronDownIcon
                                    class="chevron-small"
                                    :class="{ active: header.field === sortField, up: currentSortOrder === 'desc' }"
                                />
                            </span>
                        </th>
                        <th v-if="tableActions && tableActions.length > 0">
                            Действия
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-gray-200 dark:bg-dark-1 table-row" v-for="(row, rowIdx) in tableData" :key="rowIdx">
                        <td class="border-b dark:border-dark-5" v-for="(header, headerIdx) in headers" :key="headerIdx">
                            <template v-if="header.field === 'phone'">
                                {{ formatPhone(row[header.field]) }}
                            </template>
                            <template v-else>
                                <span v-if="header.raw" v-html="row[header.field]"></span>
                                <span v-else>{{ row[header.field] }}</span>
                            </template>
                        </td>
                        <td v-if="tableActions && tableActions.length > 0" class="border-b dark:border-dark-5">
                            <div class="actions-container">
                                <template v-for="(action, actionIdx) in tableActions" :key="actionIdx">
                                    <a
                                        v-if="
                                            action.name !== 'repeatb24' ||
                                                (action.name === 'repeatb24' && !row.crm_deal_id)
                                        "
                                        href="#"
                                        class="action-btn bg-primary-9"
                                        :class="[
                                            action.class,
                                            { hidden: action.name === 'unblock' && !row.is_blocked },
                                            { hidden: action.name === 'block' && row.is_blocked },
                                        ]"
                                        :title="
                                            action.name === 'published'
                                                ? row[action.name]
                                                    ? action.title2
                                                    : action.title
                                                : action.title
                                        "
                                        @click.prevent="onActionClick(action.cbName, row, rowIdx)"
                                    >
                                        <template v-if="action.icon === 'toggle'">
                                            <BaseCheckbox
                                                v-if="inverseToggleIcons"
                                                :id="row.id"
                                                v-model="row[action.name]"
                                            >
                                                <template v-slot:icon="{ value }">
                                                    <EyeIcon v-if="value" class="w-4 h-4" />
                                                    <EyeOffIcon v-else class="w-4 h-4" />
                                                </template>
                                            </BaseCheckbox>
                                            <BaseCheckbox v-else :id="row.id" v-model="row[action.name]" />
                                        </template>
                                        <template v-else-if="action.name === 'duplicate'">
                                            <component :is="action.icon" stroke="#fff" class="icon-small"></component>
                                        </template>
                                        <template v-else-if="action.name === 'repeatb24'">
                                            <UploadCloudIcon v-if="!row.crm_deal_id" />
                                        </template>
                                        <template v-else>
                                            <component :is="action.icon" stroke="#fff" class="icon-small"></component>
                                        </template>
                                    </a>
                                </template>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Pagination
            v-if="meta && meta.total > meta.per_page"
            :current-page="meta.current_page"
            :last-page="meta.last_page"
        />
    </div>
</template>

<script>
import Preloader from '@/components/preloader/Main';
import Pagination from '@/components/pagination/Main';
import BaseCheckbox from '@/components/base/BaseCheckbox';

export default {
    name: 'ContentTable',
    components: { BaseCheckbox, Pagination, Preloader },
    props: {
        loading: {
            type: Boolean,
        },
        headers: {
            type: Array,
            required: true,
        },
        tableRows: {
            type: Array,
            required: true,
        },
        tableActions: {
            type: Array,
            required: true,
        },
        meta: {
            type: Object,
        },
        inverseToggleIcons: Boolean,
    },
    data() {
        return {
            currentSortOrder: 'asc',
            sortField: null,
        };
    },
    computed: {
        tableData() {
            if (!(this.currentSortOrder && this.sortField)) {
                return this.tableRows;
            }
            return this.tableRows.sort((a, b) => {
                let modifier = 1;
                if (this.currentSortOrder === 'desc') modifier = -1;
                if (a[this.sortField] < b[this.sortField]) return -1 * modifier;
                if (a[this.sortField] > b[this.sortField]) return 1 * modifier;
                return 0;
            });
        },
        formatPhone() {
            return (phone) => {
                const cleaned = ('' + phone).replace(/\D/g, '');
                const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
                if (match) {
                    const intlCode = match[1] ? '+7 ' : '';
                    return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
                }
            };
        },
    },
    methods: {
        sort(field) {
            if (field === this.sortField) {
                this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
            }
            this.sortField = field;
        },
        onActionClick(eventName, row, index) {
            this.$emit(eventName, row, index);
        },
    },
};
</script>

<style scoped lang="scss">
.table-wrapper {
    background-color: #fff;
}

.chevron-small {
    width: 20px;
    height: 20px;
    transition: 0.2s;
    opacity: 0;

    &.active {
        opacity: 1;
    }

    &.up {
        transform: rotate(180deg);
    }
}

.icon-small {
    width: 15px;
    height: 15px;
}

.header-action {
    cursor: pointer;
    transition: 0.2s;

    span {
        display: block;
    }

    &.active {
        background: #fafafa;
    }
}

.table-row {
    transition: 0.2s;
    background: #fff;

    &:nth-child(odd) {
        background: #fafafa;
    }

    &:hover {
        background: #eee;
    }
}

.actions-container {
    display: flex;
}

.action-btn {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    &.hidden {
        display: none;
    }

    &:last-child {
        margin-right: 0;
    }
}
</style>
