<template>
    <div class="base-checkbox" :class="wrapSelectors">
        <input
            type="checkbox"
            :id="`${getUid}-${id}`"
            class="base-checkbox-input"
            v-bind="$attrs"
            :checked="modelValue"
            @change="updateValue"
        />

        <span class="base-checkbox-mark">
          <slot name="icon" :value="modelValue">
            <EyeOffIcon v-if="modelValue" />
            <EyeIcon v-else />
          </slot>
        </span>

        <label
            v-if="label"
            :for="`${getUid}-${id}`"
            class="base-checkbox-label"
            :class="labelSelectors"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    name: "BaseCheckbox",
    props: {
        id: null,
        label: String,
        wrapSelectors: null,
        labelSelectors: null,
        modelValue: null
    },
    inheritAttrs: false,
    computed: {
        getUid() {
            return Math.random();
        }
    },
    methods: {
        updateValue(event) {
            this.$emit("update:modelValue", event.target.value);
        }
    }
};
</script>

<style lang="scss" scoped>
.base-checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {

    }

    &-input {
        display: none;

        &:checked {
            & ~ .base-checkbox-mark {
            }

            & ~ .base-checkbox-label {
            }
        }
    }

    &-mark {
        color: #fff;

        svg {
            width: 100%;
            height: 100%;
            stroke: currentColor;
        }
    }

    &-label {
        z-index: 1;
        position: relative;
        padding-left: 24px;
        color: #000;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>
